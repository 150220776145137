import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import StoryHero from '../components/Hero/variants/StoryHero';
import BlogConversionPanel from '../components/ConversionPanel/BlogConversionPanel';
import StoryCarousel from '../components/VideoCarousel/StoryCarousel';
import ConversionPanel from '../components/ConversionPanel';
import { conversion } from '../utils/aboutDummyData';
import poly1 from '../images/story_bg1.svg';
import poly2 from '../images/story_bg2.svg';
import GeneralHead from '../components/generalHead';

const Story = ({ data: { story } }) => {
  const quoteBlock = story?.pullQuote?.value?.document?.children?.filter(
    _child => _child?.type === 'blockquote'
  )[0];
  const roleBlock = story?.pullQuote?.value?.document?.children?.filter(
    _child => _child?.type === 'paragraph'
  )[0];
  useEffect(() => {
    const domArray = document.querySelectorAll('.story-content h2');
    domArray.forEach(_h2 => {
      if (_h2.offsetHeight < 10) {
        // eslint-disable-next-line no-param-reassign
        _h2.style.display = 'none';
      }
    });
  }, []);
  return (
    <Layout forceShowHeader forceShowFooter>
      <HeaderContainer>
        <StoryHero story={story} />
      </HeaderContainer>
      <ContentWrapper>
        <Row>
          <Col md={8}>
            <div
              className="story-content"
              dangerouslySetInnerHTML={{ __html: story?.content[0]?.content }}
            />
            <BlogConversionPanel />
          </Col>
          <Col md={4}>
            <div className="side-bar">
              <h6>About {story?.company?.name}</h6>
              <p className="company-about">{story?.company?.about}</p>
              <div className="com-desc">
                <div className="com-industry">
                  <span className="lbl-title">Industry: </span>
                  <span>{story?.company?.industry?.name}</span>
                </div>
                <div className="com-size">
                  <span className="lbl-title">Company Size: </span>
                  <span>{story?.company?.companySize}</span>
                </div>
                <div className="com-location">
                  <span className="lbl-title">Location: </span>
                  <span>{story?.company?.location}</span>
                </div>
              </div>
              <div className="pull-quote-wrapper">
                <p className="quote-content">
                  {quoteBlock?.children[0]?.children[0]?.value}
                </p>
                <div className="author-info">
                  <p className="author-name">{quoteBlock?.attribution}</p>
                  <p className="author-role">{roleBlock?.children[0]?.value}</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </ContentWrapper>
      <StoryCarousel />
      <ConversionPanel
        component={{
          ...conversion,
          backgroundColor: { hex: '#171E2A' },
        }}
        bgType="solid"
      />
    </Layout>
  );
};

export const Head = ({
  data: {
    story: { seo, schemaMarkup },
  },
}) => (
  <GeneralHead>
    <SEO seo={seo} schemaMarkup={schemaMarkup} />
  </GeneralHead>
);

export default Story;

// export const query = graphql`
//   query($id: String!) {
//     story: datoCmsSuccessStory(id: { eq: $id }) {
//       title
//       excerpt
//       slug
//       schemaMarkup {
//         id
//         schema
//       }
//       results
//       author {
//         firstName
//         lastName
//         role
//       }
//       company {
//         about
//         companySize
//         name
//         logo {
//           gatsbyImageData(
//             imgixParams: {
//               fm: "webp"
//               auto: "compress"
//               maxW: 1080
//               fit: "clip"
//               q: 35
//             }
//           )
//           url
//         }
//         industry {
//           name
//         }
//         location
//       }
//       cardImage {
//         image {
//           gatsbyImageData(
//             imgixParams: {
//               fm: "webp"
//               auto: "compress"
//               maxW: 1080
//               fit: "clip"
//               q: 35
//             }
//           )
//           fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
//             ...GatsbyDatoCmsFluid
//           }
//           url
//         }
//       }
//       tags {
//         name
//         tagColor {
//           hex
//         }
//       }
//       content {
//         content
//       }
//       resultsIcons {
//         gatsbyImageData(
//           imgixParams: {
//             fm: "webp"
//             auto: "compress"
//             maxW: 1080
//             fit: "clip"
//             q: 35
//           }
//         )
//         title
//         url
//         fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
//           ...GatsbyDatoCmsFluid
//         }
//         notes
//       }
//       pullQuote {
//         blocks
//         links
//         value
//       }
//       publishDate(formatString: "MMM DD, YYYY")
//       seo: seoMetaTags {
//         ...GatsbyDatoCmsSeoMetaTags
//       }
//     }
//   }
// `;

const HeaderContainer = styled.div`
  background-color: #f0f2f5;
  background-image: url(${poly1}), url(${poly2});
  background-repeat: no-repeat;
  background-position: left top, right bottom;
  .bg-trans {
    background-color: transparent !important;
  }
`;

const ContentWrapper = styled(Container)`
  padding-top: 96px;
  padding-bottom: 100px;
  @media (max-width: 767.98px) {
    padding-top: 40px;
    padding-bottom: 50px;
  }
  .story-content {
    img {
      margin-bottom: 40px;
      display: block;
      max-width: 100%;
    }
    h2,
    h3,
    h4 {
      font-size: 28.13px;
      margin-bottom: 16px;
    }
    h6 {
      color: var(--success-color);
    }
    p {
      & + h2,
      h3,
      h4 {
        margin-top: 40px;
      }
    }
    ul {
      li {
        list-style: none;
        margin-bottom: 4px;
      }
      ul {
        margin-bottom: 8px;
      }
    }
  }
  .side-bar {
    @media (min-width: 992px) {
      width: 270px;
      margin-right: 0;
      margin-left: auto;
    }
    h6 {
      text-transform: capitalize;
      font-weight: 700;
      margin-bottom: 8px;
      letter-spacing: 0;
    }
    .company-about {
      margin-bottom: 16px;
      color: var(--text-color);
    }
    .com-desc {
      color: #7b848f;
      font-weight: bold;
      margin-bottom: 40px;
      & > div {
        margin-bottom: 16px;
      }
      .lbl-title {
        text-transform: capitalize;
        font-weight: 700;
        color: #2c3641;
      }
    }
    .pull-quote-wrapper {
      font-size: 22.5px;
      line-height: 140%;
      color: var(--text-color);
      .quote-content {
        margin-bottom: 12px;
        text-indent: -10px;
      }
      .author-name {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 0;
      }
      .author-role {
        font-size: 14.4px;
        margin-bottom: 0;
      }
    }
  }
`;
